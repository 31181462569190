import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { dbGet } from 'utils/DBFetchers';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const SectionContext = createContext({
  sections: {},
  pullSection: (key = []) => {},
  hasKey: (key) => false,
});

export const PropertyContext = createContext({
  properties: {},
  pullProperties: (key = []) => {},
  hasKey: (key) => false,
});

const DEFAULT_INTERVAL = 300 * 1000; // 5 minutos

// Puede ser usado para diferentes estados. En este caso, property y section
// Recibe una funcion que resuelve el resultado de la key.
// Analizar si usar useInterval en el root o usar useInterval en cada useSection/useProperty
export const useContentState = (storageKey, request = (key) => Promise.resolve()) => {
  const prevCache = JSON.parse(localStorage.getItem(storageKey)) || {};
  const [content, setContent] = useState(prevCache);
  const [nonCached, setNonCached] = useState({});

  const pullContent = (keys = []) => {
    const promises = keys.map((key) => request(key));
    Promise.all(promises).then((data) => {
      const newContent = {};
      data.forEach((section, index) => {
        newContent[keys[index]] = section;
      });
      const previousCacheState = JSON.parse(localStorage.getItem(storageKey)) || {};
      const newCacheState = { ...previousCacheState, ...newContent };
      localStorage.setItem(storageKey, JSON.stringify(newCacheState));
      setContent((prev) => ({ ...prev, ...newContent }));
      setNonCached((prev) => ({ ...prev, ...newContent }));
    });
  };

  useInterval(() => {
    pullContent(Object.keys(content));
  }, DEFAULT_INTERVAL);

  const hasKey = (key) => !!nonCached[key];
  return { content, pullContent, hasKey };
};

export const useSection = (keys = []) => {
  const { sections, pullSection, hasKey } = useContext(SectionContext);

  useEffect(() => {
    const updatedKeys = keys.filter((key) => !hasKey(key));
    pullSection(updatedKeys);
  }, [keys.length]);

  return { sections, pullSection };
};

export const useProperty = (propertyKeys = []) => {
  const { properties, pullProperties, hasKey } = useContext(PropertyContext);
  useEffect(() => {
    const updatedKeys = propertyKeys.filter((key) => !hasKey(key));
    pullProperties(updatedKeys);
  }, [propertyKeys.length]);

  return { properties, pullProperties };
};

export function PropertyProvider({ children }) {
  const { content, pullContent, hasKey } = useContentState('property', (key) =>
    dbGet(`property/${key}`).then(({ value }) => value)
  );
  return (
    <PropertyContext.Provider value={{ properties: content, pullProperties: pullContent, hasKey }}>
      {children}
    </PropertyContext.Provider>
  );
}

export function SectionProvider({ children }) {
  const { content, pullContent, hasKey } = useContentState('section', (key) => dbGet(`section/${key}`));
  return (
    <SectionContext.Provider value={{ sections: content, pullSection: pullContent, hasKey }}>
      {children}
    </SectionContext.Provider>
  );
}
