import React, { Suspense } from 'react';
// Toast
import 'react-toastify/dist/ReactToastify.css';
// routes
import ContentState from './contexts/ContentState';
// theme
// components
import ScrollToTop from './components/ScrollToTop';
import SessionState from './contexts/SessionState';
import './App.css';
import { SectionProvider, PropertyProvider } from './contexts/SectionContext';

const ThemeProvider = React.lazy(() => import('theme'));
const Router = React.lazy(() => import('./routes'));
const Toast = React.lazy(() => import('./components/toast'));
// ----------------------------------------------------------------------

export default function App() {
  return (
    <SectionProvider>
      <PropertyProvider>
        <ContentState>
          <SessionState>
            <ScrollToTop />
            <Suspense fallback={<></>}>
              <ThemeProvider>
                <Router />
                <Toast />
              </ThemeProvider>
            </Suspense>
          </SessionState>
        </ContentState>
      </PropertyProvider>
    </SectionProvider>
  );
}
