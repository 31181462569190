/* eslint-disable no-return-await */
import axios from 'axios';
import { Buffer } from 'buffer';

let apiUri = process.env.REACT_APP_API_URI;
if (!apiUri.includes('https://') && !apiUri.includes('localhost')) {
  apiUri = `https://${apiUri}`;
}

// DATA FUNCTIONS
const getToken = () => localStorage.getItem('token');

const getAuthData = (type) => {
  const token = getToken();
  const requestData = {
    method: type,
    headers: { Authorization: `Bearer ${token || ''}` },
  };
  return requestData;
};

const contentAuthData = (type, body, contentType = 'application/json') => {
  const token = getToken();
  const requestData = {
    method: type,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': contentType,
    },
  };
  if (body) requestData.body = JSON.stringify(body);
  if (contentType === 'text/html') requestData.headers.Accept = 'text/html'; // Set the 'Accept' header for HTML content
  return requestData;
};

async function getResponseData(response, data) {
  const { status } = response;
  if (status >= 200 && status < 300) {
    return data;
  }
  if (status === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('cartLength');
  }
  return Promise.reject(data);
}

async function dbGet(category) {
  const requestData = getAuthData('GET');
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

function dbGetSSE(category, updateFunction) {
  const eventSource = new EventSource(`${apiUri}/${category}`);

  eventSource.onmessage = (e) => {
    const parsedData = JSON.parse(e.data);
    updateFunction(parsedData);
    if (!window.location.pathname.includes('upload')) eventSource.close();
    /*  let allCompleted = true;
    for (let i = 0; i < parsedData.length; i += 1) {
      if (parsedData[i].progress !== 100) allCompleted = false;
    }
    if (allCompleted) eventSource.close(); */
  };

  eventSource.onopen = () => {
    console.log('SSE connection established.');
  };

  eventSource.onerror = (error) => {
    console.error('Error occurred:', error);
    eventSource.close();
  };
  return eventSource;
}

async function dbPut(category, putData) {
  const requestData = contentAuthData('PUT', putData);
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function getPDF(path, data, download) {
  const response = await axios.post(`${apiUri}/${path}`, data, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    responseType: 'blob',
  });

  const blob = new Blob([response.data], { type: 'application/pdf' });

  const url = window.URL.createObjectURL(blob);
  if (!download) return url;
  // Create an anchor tag and trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0];
  a.style.display = 'none';
  document.body.appendChild(a);
  console.log(a);

  a.click();

  // Clean up the temporary URL and anchor tag
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

async function getDataTableCSV(path, fileName = 'data') {
  const requestData = getAuthData('GET');
  const response = await axios({
    url: `${apiUri}/${path}`, // Replace with your API endpoint
    responseType: 'blob', // Important: responseType should be 'blob'
    headers: requestData.headers,
  });

  // Create a Blob from the response data
  const blob = new Blob([response.data], { type: 'text/xlsx' });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.xlsx`;

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);

  // Revoke the Blob URL to free up resources
  URL.revokeObjectURL(url);
}

async function getHTML(path, data) {
  const requestData = contentAuthData('POST', data);
  const response = await fetch(`${apiUri}/${path}`, requestData);
  const responseJson = /* parse response into html */ await response.text();

  return await getResponseData(response, responseJson);
}

async function dbPost(category, postData) {
  const requestData = contentAuthData('POST', postData);
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function dbDelete(category, deleteData) {
  const requestData = contentAuthData('DELETE', deleteData);
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function dbUploadFile(endpoint, file, extraData = undefined) {
  const formData = new FormData();
  formData.append('file', file); // Append the file itself
  formData.append('filesize', file.size); // Append the file size
  if (extraData) {
    Object.keys(extraData).forEach((key) => {
      formData.append(key, extraData[key]);
    });
  }
  const token = getToken();

  const toSend = axios.create({
    baseURL: apiUri,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
  });
  const response = await toSend.post(endpoint, formData);
  const { data } = response;
  return await getResponseData(response, data);
}

async function Login(userData) {
  const encodedData = Buffer.from(`${userData.username}:${userData.password}`).toString('base64');
  const requestData = {
    method: 'POST',
    headers: { Authorization: `Basic ${encodedData}` },
  };
  const response = await fetch(`${apiUri}/auth/login`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function PinLogin(pin) {
  const encodedData = Buffer.from(`${pin}`).toString('base64');
  const requestData = {
    method: 'POST',
    headers: { Authorization: `Basic ${encodedData}` },
  };
  const response = await fetch(`${apiUri}/auth/pin-login`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function Reset(email) {
  const currentURL = `${window.location.protocol}//${window.location.host}`;
  const encodedData = Buffer.from(`${email}`).toString('base64');
  const requestData = {
    method: 'POST',
    headers: {
      Authorization: `Basic ${encodedData}`,
      'Origin': currentURL,
    },
  };
  console.log(requestData);
  const response = await fetch(`${apiUri}/auth/password_reset_email`, requestData);
  
  const data = await response.json();

  return await getResponseData(response, data);
}

async function CompleteRegistration(endpoint, data) {
  const currentURL = `${window.location.protocol}//${window.location.host}`;
  const dataWithOrigin = { ...data, Origin: currentURL };
  return dbPost(endpoint, dataWithOrigin);
}

export {
  dbGet,
  dbPut,
  dbDelete,
  dbPost,
  Login,
  Reset,
  CompleteRegistration,
  dbUploadFile,
  dbGetSSE,
  PinLogin,
  getPDF,
  getHTML,
  getDataTableCSV,
};
