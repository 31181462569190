import React, { useReducer, useMemo, useContext } from 'react';
import { CONTENT_STATE_STATUS } from 'constants/contentState';
import ContentContext from './ContentContext';

function contentReducer(state, action) {
  switch (action.type) {
    case 'SET_PROPERTIES': {
      localStorage.setItem('properties', JSON.stringify(action.payload.properties));
      return {
        ...state,
        properties: action.payload.properties,
      };
    }
    case 'SET_SECTIONS': {
      localStorage.setItem('sections', JSON.stringify(action.payload.sections));
      return {
        ...state,
        sections: action.payload.sections,
      };
    }
    case 'SET_PROVIDERS': {
      const providers = action.payload;
      localStorage.setItem('providers', JSON.stringify(providers));
      return {
        ...state,
        providers,
      };
    }
    case 'SET_LISTS': {
      const lists = action.payload;
      localStorage.setItem('lists', JSON.stringify(lists));
      return {
        ...state,
        lists,
      };
    }
    case 'SET_COUNTRIES': {
      const countries = action.payload;
      localStorage.setItem('countries', JSON.stringify(countries));
      return {
        ...state,
        countries,
      };
    }
    case 'SET_STATES': {
      const states = action.payload;
      localStorage.setItem('states', JSON.stringify(states));
      return {
        ...state,
        states,
      };
    }
    case 'SET_CATEGORIES': {
      const categories = action.payload;
      localStorage.setItem('categories', JSON.stringify(categories));
      return {
        ...state,
        categories,
      };
    }
    case 'SET_OFFERS': {
      const offers = action.payload;
      localStorage.setItem('offers', JSON.stringify(offers));
      return {
        ...state,
        offers,
      };
    }
    case 'SET_CATEGORIES_HEIGHT': {
      const categoriesHeight = action.payload;
      localStorage.setItem('categoriesHeight', JSON.stringify(categoriesHeight));
      return {
        ...state,
        categoriesHeight,
      };
    }
    case 'SET_STATUS': {
      const status = action.payload;
      return {
        ...state,
        status,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function ContentState({ children }) {
  const sections = localStorage.getItem('sections') ? JSON.parse(localStorage.getItem('sections')) : {};
  const properties = localStorage.getItem('properties') ? JSON.parse(localStorage.getItem('properties')) : {};
  const providers = localStorage.getItem('providers') ? JSON.parse(localStorage.getItem('providers')) : [];
  const countries = localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : [];
  const states = localStorage.getItem('states') ? JSON.parse(localStorage.getItem('states')) : [];
  const categories = localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [];
  const offers = localStorage.getItem('offers') ? JSON.parse(localStorage.getItem('offers')) : [];
  let categoriesHeight = localStorage.getItem('categoriesHeight')
    ? JSON.parse(localStorage.getItem('categoriesHeight'))
    : 0;
  categoriesHeight = categoriesHeight ?? 90;
  const status = CONTENT_STATE_STATUS.EMPTY;
  const [contentState, contentDispatch] = useReducer(contentReducer, {
    sections,
    properties,
    providers,
    countries,
    states,
    categories,
    offers,
    categoriesHeight,
    status,
  });
  const value = useMemo(() => ({ contentState, contentDispatch }), [contentState]);
  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
}

export const useContent = () => useContext(ContentContext);
