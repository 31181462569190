/* eslint-disable import/no-unresolved */
import React from 'react';
// scroll bar


import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { I18nextProvider } from 'react-i18next'; // Importar el proveedor
import i18n from './i18n';
import App from './App';
import './index.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// ----------------------------------------------------------------------
// After
function AppWithCallbackAfterRender() {
  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </I18nextProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);



