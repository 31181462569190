import { loadScript } from "@paypal/paypal-js";

export function loadPayPalScript(clientId) {
  loadScript({ clientId, disableFunding: 'card' })
    .then((paypal) => {
      window.paypal = paypal;
    })
    .catch((error) => {
      console.error("failed to load the PayPal JS SDK script", error);
    });
}


export function handlePayPalScript(isAuthenticated) {
  if (isAuthenticated) {
    loadPayPalScript(process.env.REACT_APP_PAYPAL_CLIENT_ID);
  }
};
