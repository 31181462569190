import React, { useReducer, useMemo, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SessionContext from './SessionContext';
import { handlePayPalScript } from '../utils/paypal';

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN': {
      localStorage.setItem('token', action.payload.token);
      const perms = action.payload.role.permissions;
      localStorage.setItem('permissions', btoa(JSON.stringify(perms)));
      localStorage.setItem('balance', JSON.stringify(action.payload.balance));
      localStorage.setItem('cartLength', JSON.stringify(action.payload.cart_length));
      const user = {
        username: action.payload.username,
        email: action.payload.email,
        id: action.payload.id,
        wholesaler_id: action.payload.wholesaler?.id,
      };
      localStorage.setItem('user', JSON.stringify(user));
      window.location.reload();
      return {
        ...state,
        permissions: perms,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('permissions');
      localStorage.removeItem('cartLength');
      localStorage.removeItem('balance');
      window.location.href = '/';
      return { ...state, isAuthenticated: false, user: {} };
    case 'ACTIVATE_CUSTOM_QUOTES':
      return { ...state, customQuotes: true };
    case 'DEACTIVATE_CUSTOM_QUOTES':
      return { ...state, customQuotes: false };
    case 'SET_CART_LENGTH':
      localStorage.setItem('cartLength', JSON.stringify(action.payload));
      return { ...state, cartLength: action.payload };
    case 'SET_BALANCE':
      localStorage.setItem('balance', JSON.stringify(action.payload));
      return { ...state, balance: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function SessionState({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const customQuotes = !!searchParams.get('customQuotes');
  const cartLength = localStorage.getItem('cartLength') ? JSON.parse(localStorage.getItem('cartLength')) : 0;
  const [sessionState, sessionDispatch] = useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('token'),
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
    permissions: localStorage.getItem('permissions') ? JSON.parse(atob(localStorage.getItem('permissions'))) : [],
    customQuotes,
    cartLength,
  });

  useEffect(() => {
    handlePayPalScript(sessionState.isAuthenticated);
  }, [sessionState.isAuthenticated]);

  const value = useMemo(() => ({ sessionState, sessionDispatch }), [sessionState]);
  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}

export const useSession = () => useContext(SessionContext);
